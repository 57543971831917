import React from 'react';
import {useSelector} from 'react-redux';
import {CSSTransition} from 'react-transition-group';
import i18n from '../../../../../../config/i18n';
import {PAGE_PATH} from '../../../../../utils';
import {Button} from '../../../../../components/shared';
import {Description, Title, Text} from './Intro.styles';

type IntroProps = {
  isAllKeysButtonClicked: boolean;
  setIsAllKeysButtonClicked: (val: boolean) => void;
}

const Intro = ({isAllKeysButtonClicked, setIsAllKeysButtonClicked}: IntroProps) => {
  const keysGold = useSelector((state: any) => state?.transactionsEYC?.gold);
  const isUserWonEYC = useSelector((state: any) => state?.isUserWonEYC);
  const isDefineWinnerCompleted = useSelector((state: any) => state?.isDefineWinnerCompletedEYC);
  const currentPrize = useSelector((state: any) => state?.currentPrizeDataEYC);
  // const isReplayGame = useSelector((state: any) => state?.isReplayGameEYC);

  return (
    <Description>
      <CSSTransition
        in={!keysGold && currentPrize?.prize?.code !== '1000000'}
        timeout={{appear: 1000, enter: 1000, exit: 0}}
        classNames='anim-intro-2'
        unmountOnExit
      >
        <div>
          <Title>{i18n.t('eyc_2024_2_title_noTokens')}</Title>
          <Text>{i18n.t('eyc_2024_2_text_noTokens')}</Text>
          <Button backgroundcolor={'#F37427'}
                  text={i18n.t('eyc_2024_2_btn_noTokens')}
                  url={`/${PAGE_PATH.EYC_2024_LANDING}`}>
          </Button>
        </div>
      </CSSTransition>

      <CSSTransition
        in={!!keysGold}
        timeout={{appear: 1000, enter: 1000, exit: 0}}
        classNames='anim-intro-2'
        unmountOnExit
      >
        <div>
          <Title>{i18n.t('eyc_2024_2_title_tokens')}</Title>
          <Text>{i18n.t('eyc_2024_2_text_tokens')}</Text>

          {keysGold >= 3 &&
            <Button backgroundcolor={'#F37427'}
                    disabled={isAllKeysButtonClicked}
                    text={i18n.t('eyc_2024_all_keys_button')}
                    onClick={() => setIsAllKeysButtonClicked(true)}>
            </Button>
          }
        </div>
      </CSSTransition>

      <CSSTransition
        in={isUserWonEYC && isDefineWinnerCompleted && currentPrize?.prize?.code === '1000000'}
        timeout={{appear: 1000, enter: 1000, exit: 0}}
        classNames='anim-intro-2'
        unmountOnExit
      >
        <div style={{zIndex: currentPrize?.prize?.code === '1000000' ? 22 : 1}}>
          <Title>{i18n.t('eyc_2024_2_title_win')}</Title>
          <Text>{i18n.t('eyc_2024_2_text_win')}</Text>
          <Button backgroundcolor={'#F37427'}
                  text={i18n.t('eyc_2024_rewards_btn_2')}
                  url={'/rewards/all'}
                  className={'winState'}>
          </Button>
        </div>
      </CSSTransition>

    </Description>
  );
};

export default Intro;