import styled, { css } from 'styled-components';
import { Theme } from '../../../../../theme';
import { EssoCardProps } from './EssoCard';
import { EssoCardBlob, IconLogoEsso } from '../../../../../assets';

export const CardWrapper = styled.div<EssoCardProps>(({ isProfileMenu = false }) => {
  return css`
    cursor: ${() => isProfileMenu ? 'pointer' : 'default'};
    position: relative;
    width: 100%;
    aspect-ratio: 3/2;
    max-width: ${() => isProfileMenu ? '260px' : '46px'};
    max-height: ${() => isProfileMenu ? '165px' : '30px'};
    background: linear-gradient(315deg, #007DDE 0%, #0047BA 100%);
    border-radius: ${() => isProfileMenu ? '4px' : '1px'};
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    font-size: 14px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 45%;
      height: 45%;
      background: url('${EssoCardBlob}') no-repeat bottom right;
      background-size: cover;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 6%;
      left: 4%;
      // top: ${() => isProfileMenu ? '10px' : '3%'};
      // left: ${() => isProfileMenu ? '10px' : '3%'};
      width: 16%;
      height: 17%;
      max-width: 40px;
      max-height: 28px;
      // width: ${() => isProfileMenu ? '40px' : '7px'};
      // height: ${() => isProfileMenu ? '28px' : '5px'};
      background: url('${IconLogoEsso}') no-repeat bottom right;
      background-size: cover;
    }

    ${Theme.mediaQueries.xs} {
      max-width: ${() => isProfileMenu ? '220px' : '43px'};
      max-height: ${() => isProfileMenu ? '140px' : '28px'};
    }
  `;
});

export const CardBalance = styled.div(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    position: absolute;
    top: 6%;
    right: 4%;
    //right: 10px;
    //top: 10px;
    width: 50%;
    text-align: right;

    span {
      font-family: ${font.family.EMPrintRegular};
      line-height: 1.05;
      color: ${color.white};

      &:first-child {
        font-size: ${size.px20};
        font-weight: ${font.weight.semiBold};
      }

      &:last-child {
        font-size: ${size.px14};
        font-weight: ${font.weight.regular};
      }
    }

    ${mediaQueries.md} {
    }
  `;
});

export const CardInfo = styled.div<EssoCardProps>(({ isProfileMenu = false, theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 6%;
    left: 4%;
    //left: 10px;
    //bottom: 10px;
    text-align: left;

    span {
      font-family: ${font.family.EMPrintRegular};
      line-height: 1.05;
      font-size: ${() => isProfileMenu ? size.px14 : '3px'};
      font-weight: ${font.weight.regular};
      color: ${color.white};

      ${mediaQueries.xs} {
        font-size: ${() => isProfileMenu ? size.px12 : '2.5px'};
      }

      &:last-child {
        padding-top: 3%;
        font-family: 'Ocra', serif;
      }
    }
  `;
});

export const CardQRCode = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    bottom: 6%;
    right: 4%;
    //right: 10px;
    //bottom: 10px;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
    }

    ${mediaQueries.md} {
    }
  `;
});

export const CardDots = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    width: 3px;
    height: 5px;
    top: 6%;
    right: 4%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${mediaQueries.md} {
    }
  `;
});

export const Dots = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    position: relative;
    width: 1px;
    height: 1px;
    background: ${color.white};
    border-radius: 50%;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 1px;
      height: 1px;
      background: ${color.white};
      border-radius: 50%;
    }

    &:before {
      top: -2px;
    }

    &:after {
      top: 2px;
    }

    ${mediaQueries.md} {
    }
  `;
});
