import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';
import {isWebView} from '../../../../components/utils/_app-helpers';
import { useProfileMenuLinkActive } from '../../../hooks';
import getProfileMenuLinks from '../../../../prepr/queries/getProfileMenuLinks';
import { URLProps } from '../../../../prepr/interfaces/Page';
import {
  NavLinkCustom,
  LinkCustom,
  Icon,
  Text
} from './ProfileMenu.styles';

interface ProfileMenuLinkTypes {
  cta_label?: string;
  cta_icon?: string;
  cta_active_icon?: string;
  cta_url?: string;
  order?: number;
  internal_link?: URLProps[];
  is_cta_url_external?: boolean;
}

export interface ProfileMenuProps {
  layout: 'header' | 'profile';
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ layout = 'header' }) => {
  const webView = isWebView();
  const [profileMenuLinks, setProfileMenuLinks] = useState<ProfileMenuLinkTypes[]>([]);
  const [errors, setErrors] = useState<string[]>();
  const checkIsUrlActive = useProfileMenuLinkActive();

  const fetchProfileMenuLinks = useCallback(async () => {
    try {
      const data = await getProfileMenuLinks;
      if (data.length > 0) {
        const filteredProfileMenuLinks = data.filter(
          ({ cta_label, cta_url, internal_link }: ProfileMenuLinkTypes) =>
            (cta_url !== null && cta_label !== null) || (internal_link !== null && cta_label !== null)
        ).sort((a: any, b: any) => a?.order - b?.order);

        setProfileMenuLinks(filteredProfileMenuLinks);
      }
    } catch (e) {
      setErrors(['There is an error']);
    }
  }, []);

  useEffect(() => {
    fetchProfileMenuLinks();
  }, [fetchProfileMenuLinks]);

  return (
    !webView ?
      <>
        {errors?.map((i) => i)}
        {
          profileMenuLinks?.length && profileMenuLinks.map((item, i) => {
            const pageURL = item?.internal_link?.[0];
            const link = pageURL ? pageURL._slug : item?.cta_url;
            return (
              link && item?.cta_label && (
                (!!pageURL || (!pageURL && !item?.is_cta_url_external)) ? (
                    <NavLinkCustom key={uuidv4()}
                                   to={`/${link}`}
                                   isActive={() => checkIsUrlActive(`/${link}`)}>
                      {
                        layout === 'header' ?
                          <Icon>{item?.cta_active_icon && parse(item?.cta_active_icon)}</Icon> :
                          <>
                            {
                              checkIsUrlActive(`/${link}`) ?
                                <Icon>{item?.cta_active_icon && parse(item?.cta_active_icon)}</Icon> :
                                <Icon>{item?.cta_icon && parse(item?.cta_icon)}</Icon>
                            }
                          </>
                      }
                      <Text>{item?.cta_label}</Text>
                    </NavLinkCustom>
                  ) :
                  <LinkCustom key={uuidv4()}
                              href={link}
                              target='_blank'>
                    {
                      layout === 'header' ?
                        <Icon>{item?.cta_active_icon && parse(item?.cta_active_icon)}</Icon> :
                        <>
                          {
                            checkIsUrlActive(`/${link}`) ?
                              <Icon>{item?.cta_active_icon && parse(item?.cta_active_icon)}</Icon> :
                              <Icon>{item?.cta_icon && parse(item?.cta_icon)}</Icon>
                          }
                        </>
                    }
                    <Text>{item?.cta_label}</Text>
                  </LinkCustom>
              )
            );
          })
        }
      </> :
      <></>
  );
};

export default ProfileMenu;
