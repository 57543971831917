import React from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../../../config/i18n';
import {isWebView} from '../../../../components/utils/_app-helpers';
import { EssoCard } from '../index';
import { ProfileBannerWrapper, Intro } from './ProfileBanner.styles';

const ProfileBanner = () => {
  const webView = isWebView();
  const firstName = useSelector((state: any) => state?.currentUser?.firstName);
  const masterCardNumber = useSelector((state: { currentUser: any }) => state?.currentUser?.masterCardNumber);

  return (
    !webView ?
      <ProfileBannerWrapper>
        <Intro>
          <span>{i18n.t('profile_banner_intro_text')}</span>&nbsp;
          <br/>
          <span>{firstName}</span>
        </Intro>
        <EssoCard isProfileMenu={true} cardNumber={masterCardNumber}/>
      </ProfileBannerWrapper> :
      <></>
  );
};

export default ProfileBanner;